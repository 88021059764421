#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
  }
  
  #progressbar .active {
    color: #000000;
  }
  
  #progressbar li {
    list-style-type: none;
    font-size: 12px;
    width: 25%;
    float: left;
    position: relative;
  }
  
  #progressbar #work:before {
    font-family: FontAwesome;
    content: "\f0b1";
  }
  
  #progressbar #personal:before {
    font-family: FontAwesome;
    content: "\f007";
  }
  
  #progressbar #education:before {
    font-family: FontAwesome;
    content: "\f501";
  }
  
  #progressbar #confirm:before {
    font-family: FontAwesome;
    content: "\f00c";
  }
  
  #progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 18px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px;
  }
  
  #progressbar li:after {
    content: "";
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1;
  }
  
  #progressbar li.active:before,
  #progressbar li.active:after {
    background: #e3da2d;;
  }


  .PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    display: block;
    width: 100%;
    padding: 0.4375rem 0.875rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.53;
    color: #697a8d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d9dee3;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}