#email-confirm.container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}



.card-confirm {
    color: #111010;
    width: 325px;
    font-size: 14px;
    margin: auto;
}

.card-confirm .card-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
}

.card-confirm .card-head {
    border-bottom: none;
    position: relative;
}



.card-confirm .icon-box {
    color: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -70px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 9;
    background: #82ce34;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

div.card-confirm .icon-box i {
    font-size: 58px;
    position: relative;
    top: 3px;
}

.card-confirm.card-dialog {
    margin-top: 80px;
}

.card-confirm .btn {
    color: #fff;
    border-radius: 4px;
    background: #82ce34;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border: none;
}

.card-confirm .btn:hover,
.card-confirm .btn:focus {
    background: #6fb32b;
    outline: none;
}

.trigger-btn {
    display: inline-block;
    margin: 100px auto;
}