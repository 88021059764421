.g-4 {
    margin: -0.75rem !important;
  }
  .g-4 > div {
    padding: 0.75rem !important;
  }
  
  @media (min-width: 768px) {
    .d-md-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 768px) {
    .nk-block-between-md {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }